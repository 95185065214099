(function($){'use strict';jQuery(document).ready(function($){function readMore(string,maxWords){var strippedString=$("<p>"+string+"</p>").text().trim();var array=strippedString.split(" ");var string=array.splice(0,maxWords).join(" ");if(array.length>maxWords){string+="...";}
return string;}
function convert_currency_andy(value,type){var newCurrency=Currency.currentCurrency;if(Currency.currentCurrency===undefined)newCurrency='USD';var oldCurrency=shopCurrency;if(isNaN(value)){value=0.0;}
var cents=0.0;var oldFormat=Currency.moneyFormats['USD'][Currency.format]||'';var newFormat=Currency.moneyFormats[newCurrency][Currency.format]||'';var arr=[];arr=newFormat.split(" ");newFormat=arr[0];if(Currency.currentCurrency===undefined){var newFormat=Currency.moneyFormats['USD'][Currency.format]||'';}
if(oldFormat.indexOf('amount_no_decimals')!==-1){cents=Currency.convert(parseInt(value,10)*100,oldCurrency,newCurrency);}else if(oldCurrency==='JOD'||oldCurrency=='KWD'||oldCurrency=='BHD'){cents=Currency.convert(parseInt(value,10)/10,oldCurrency,newCurrency);}else{cents=Currency.convert(parseInt(value,10),oldCurrency,newCurrency);}
if(type=='nosymbol'){return cents;}
var my_data=Currency.formatMoney(cents,newFormat);return my_data;}
function show_dropdown_cart(){$.ajax({url:' /cart.js',type:'GET',dataType:'json',success:function(data){$(".cartCount").text(data.item_count);$(".minicart-content").empty();$(".minicart-content .total-price").empty();var html='';if(data.item_count>0){var index_item=0;html=html+'<div class="cart-hover-title">Your Products <span>Prices</span></div>';html=html+'<ul class="list-hover-cart">';$.each(data.items,function(index,el){html=html+'<li class="hover-cart-item" id="item-'+el.id+'">';html=html+'<a href="javascript:void(0);" class="img-hover-cart">';html=html+'<img src="'+el.image+'" alt="'+el.title+'" width="75" height="89">';html=html+'<span class="delete-product-hover-cart remove-item" data-id="'+el.id+'"><i class="fa fa-times-circle" aria-hidden="true"></i></span>';html=html+'</a>';html=html+'<div class="text-hover-cart">';html=html+'<a href="'+el.url+'" class="name-hover-cart">'+el.title+'</a>';html=html+'<div class="quantity">';html=html+'<p class="quanlity-hover-cart">';html=html+'Quantity: <span>'+el.quantity+'</span>';html=html+'</p>';html=html+'</div>';html=html+'</div>';html=html+'<div class="price-hover-cart"><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency_andy(el.price,'xx')+'">'+convert_currency_andy(el.price,'xx')+'</span></div>';html=html+'</li>';})
html=html+'</ul>';html=html+'<div class="subtotal-hover-cart ">Total  <span class="total-price total-price"><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency_andy(data.price,'11')+'" data-currency-'+Currency.currentCurrency+'="'+convert_currency_andy(data.price,'11')+'" data-currency="'+Currency.currentCurrency+'"></span></span></div>';html=html+'<div class="button-cart-hover">';html=html+'<a href="/cart" class="go-to-cart button">Go to cart</a>';html=html+'<a href="/checkout" class="check-out button orange">Check out</a>';html=html+'</div>';}else{html=html+'<div class="cart-empty cart-hover">';html=html+'<p class="content" style="margin:20px;">Your cart is currently empty.</p>';html=html+'</div>';}
$(".minicart-content").html(html);$(".minicart-content .total-price").html(''+convert_currency_andy(data.total_price));}})}
$(document).on('click',".wishlist",function(event){var $this=$(this);var myid=$(this).data('form');var args=$("input[value='"+myid+"']").first().parent('form').serialize();event.preventDefault();if($this.hasClass("wishlist-login")){sweetAlert("Oops...","Please login to use wishlist!","error");}else{$this.removeClass("wishlist");swal("Success!","Added successfully to wishlist!","success");$this.parent().find(".contact-form").submit();$this.parent().addClass("active")}});$(document).on('click',".wishlist-login",function(event){event.preventDefault();swal({title:"Oops...",text:"Please login to use wishlist!",type:"warning",confirmButtonText:"Login",timer:5000,showCancelButton:true},function(){window.location.href='https://'+Shopify.shop+'/account/login'});});$("#addtocart-message").hide('slow');$(document).on('click',".wishlist-addtocart",function(event){event.preventDefault();var $this=$(this);$("#loading").show();var value=$(this).data('wid');$('.product-select').val(value);var args=$('.add-variant').serialize();$.ajax({url:$('.add-variant').attr('action'),type:'POST',dataType:'html',data:args,success:function(data,status){show_dropdown_cart();$("#addtocart-message").show('slow');var xx=$this.data('title');$("."+value).hide();$("#title-addtocart").text(xx);if(status=="success"){$this.hide(600).next('.wishlist-addedtocart').removeClass('wishlist-addedtocart').show(700);$("#loading").hide(500);}else{$("#loading").hide(500);sweetAlert("Oops...","An error occurred when add to cart!","error");}},error:function(xhr,ajaxOptions,thrownError){$("#loading").hide(500);}});});$('.wishlist-remove').on('click',function(event){event.preventDefault();$("#loading").show();var $this=$(this);var value=$(this).data('wid');$('.remove-value').val(value);var args=$('.contact-form').serialize();$(".contact-form").submit();});var storage=$.localStorage;var compare={};var total_compare=4;if(storage.isSet('compare')){compare=storage.get('compare');}else{storage.set('compare',{});}
function compare_to_table(data){if(Object.keys(data).length<=0){return'';}
var html='';var i=0;var end_check=(Object.keys(data).length-1);var width_tr=(end_check>0)?(90/(Object.keys(data).length)):90;var data_html='';for(i=0;i<=end_check;i++){var el=data[i];var is_sale=false;if(el.compare_at_price>el.price){is_sale=true}
data_html=data_html+'<th class=" '+el.handle+'"><button type="button" class="close remove-compare center-block" aria-label="Close" data-handle="'+el.handle+'"><span aria-hidden="true" class="remove-compare-x" title="Delete" style="font-size: 13px;"></span></button></th>';if(i==0){html=html+'<tr >';html=html+'<th width="10%" > Title </th>';}
html=html+'<td width="'+width_tr+'%"  class="'+el.handle+'"> '+el.title+'  </td>';if(i>=end_check){html=html+'</tr>';}}
for(i=0;i<=end_check;i++){var el=data[i];var is_sale=false;if(el.compare_at_price>el.price){is_sale=true}
if(i==0){html=html+'<tr>';html=html+'<th width="10%" > Image </th>';}
html=html+'<td width="'+width_tr+'%" class="'+el.handle+'"> <div style="width:150px; height:auto; margin:0 auto;"><img style="float:left;" src="'+el.featured_image+'"  width="150"/> '+'<div class="product-price" style="width: 150px; margin: 0;text-align:center;  clear:left; float: left;" > ';if(is_sale){html=html+'<strong style="display:block;">On Sale</strong>'+'<span class="price-sale"><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency_andy(el.price,'11')+'">'+convert_currency_andy(el.price,'11')+'</span></span>';}else{html=html+'<span class="price-sale"><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency_andy(el.price,'11')+'">'+convert_currency_andy(el.price,'11')+'</span></span>';}
html=html+'</div>';if(el.variants.length>1){html=html+'<div style="width: 150px; margin: 0;text-align:center; clear:left; float: left;"><a style="cursor:pointer;" onclick="location.href=\'/products/'+el.handle+'\'">Select Options</a></div>';}else{html=html+'<div style="width: 150px; margin: 0;text-align:center; clear:left; float: left;"><a style="cursor:pointer;" href="#" title="ADD TO CART" data-pid="'+el.variants[0].id+'" class="button add_to_cart_button ">ADD TO CART</a></div>';}
html=html+' </div></td>';if(i>=end_check){html=html+'</tr>';}}
for(i=0;i<=end_check;i++){var el=data[i];var is_sale=false;if(el.compare_at_price>el.price){is_sale=true}
if(i==0){html=html+'<tr>';html=html+'<th width="10%" > Description </th>';}
html=html+'<td width="'+width_tr+'%" class="'+el.handle+' "> <p class="description-compare" style="text-align:justify;"> '+readMore(el.description,25)+'... </p> </td>';if(i>=end_check){html=html+'<tr>';}}
for(i=0;i<=end_check;i++){var el=data[i];var is_sale=false;if(el.compare_at_price>el.price){is_sale=true}
if(i==0){html=html+'<tr>';html=html+'<th width="10%" > Available </th>';}
var avai_stock=(el.available)?'Available In stock':'Unavailable In stock';html=html+'<td   width="'+width_tr+'%" class="available-stock '+el.handle+'"> <p> '+avai_stock+' </p> </td>';if(i>=end_check){html=html+'<tr>';}}
$(".th-compare").html('<th></th>'+data_html);$("#table-compare").html(html);}
function compare_to_div(data){if(Object.keys(data).length<=0){return'';}
var html='';var i=0;var end_check=(Object.keys(data).length-1);html=html+'<div class="bestseller-sidebar">';html=html+'<ul class="bestseller-list">';for(i=0;i<data.length-1;i++){var el=data[i];html=html+'<li class="bestseller-item" style="padding-top:0px;">';html=html+'<a href="">';html=html+'<img src="'+el.featured_image+'" alt="" style="width:112px; height:130px;" >';html=html+'</a>';html=html+'<div class="bestseller-content">';html=html+'<a href="/products/'+el.handle+'" class="bestseller-text">'+el.title+'</a>';html=html+'<span><span class="money" data-currency-'+Currency.currentCurrency+'="'+convert_currency_andy(el.price,'11')+'">'+convert_currency_andy(el.price,'11')+'</span></span>';html=html+'</div>';html=html+'</li>';}
$(".compare-sidebar-content").html(html);}
function countProperties(obj){var count=0;for(var prop in obj){if(obj.hasOwnProperty(prop))
++count;}
return count;}
if(!$.isEmptyObject(compare)){var list_id='';var json_compare=[];var check_end=0;var compare_size=(Object.keys(compare).length-1);$.each(compare,function(index,el){$("[data-pid='"+el+"']").addClass('added');$("[data-pid='"+el+"']").attr("title","Added compare");var json_url="/products/"+el+".js";if($.trim(el)!=""){jQuery.getJSON(json_url,function(product){json_compare[check_end]=product;if(check_end>=compare_size){$("#compare-sidebar .no-compare").hide();compare_to_table(json_compare);}
check_end+=1;});}});$(".compare_count").text("0");$(".compare_count").text(countProperties(compare));$(".compare_show_count").text(countProperties(compare));$("#compare_value").val(countProperties(compare));}else{compare={};$(".error-compare").show();}
function modal_compare(){if(!$.isEmptyObject(compare)){var list_id='';var json_compare=[];var check_end=0;var compare_size=(Object.keys(compare).length-1);$.each(compare,function(index,el){var json_url="/products/"+el+".js";if($.trim(el)!=""){jQuery.getJSON(json_url,function(product){json_compare[check_end]=product;if(check_end>=compare_size){compare_to_table(json_compare);}
check_end+=1;});}});$("#moda-compare").modal('show');}}
$(document).on('click','.show_compare',function(event){if(!$.isEmptyObject(compare)){modal_compare(compare);}else{compare={};modal_compare(compare);}})
$(document).on('click','.compare',function(event){event.preventDefault();$("#loading").show();var $this=$(this);var pid=$(this).data('pid');compare=storage.get('compare');if($.isEmptyObject(compare)){compare={};}
var check_compare=true;if(Object.keys(compare).length>=total_compare){swal({title:"info",text:"Product Added over 4 product !. Do you want to compare 4 added product ?",type:"warning",showCancelButton:true,confirmButtonColor:"#4cae4c",confirmButtonText:"Yes,I want view it!",timer:3000,cancelButtonText:"Continue",closeOnConfirm:true},function(){modal_compare(compare);});}else{for(var i=1;i<=total_compare;i++){if(compare['p'+i]==""||compare['p'+i]==undefined){compare['p'+i]=pid;break;}else if(compare['p'+i]==pid){$this.addClass('added');check_compare=false;modal_compare(compare);break;}}
if(check_compare){storage.set('compare',compare);modal_compare(compare);$this.addClass('add-success');$("[data-pid='"+pid+"']").addClass('added');$("[data-pid='"+pid+"']").attr("title","Added compare");var count=parseInt($("#compare_value").val());if($this.hasClass("refresh-product")){}else{$this.parent().addClass("active");}
$(".compare_count").text("");count++;$(".compare_count").text(count);$("#compare_value").val(count);$(".mfp-close").trigger("click");}}
$("#loading").hide(500);});$(document).on('click','.remove-compare',function(event){event.preventDefault();var id=$(this).data('handle');$("."+id).fadeOut(600).remove();$("[data-pid='"+id+"']").removeClass('added add-success');$.each(compare,function(index,el){if(el==id){compare[index]="";delete compare[index];}});storage.set('compare',compare);var count=parseInt($("#compare_value").val());count--;$(".compare_count").text(count);$(".compare_show_count").text(count);$("#compare_value").val(count);});$(document).on('click','.compare_setting',function(event){event.preventDefault();if(!$.isEmptyObject(compare)){modal_compare(compare);}else{compare={};modal_compare(compare);}});if(!$.isEmptyObject(compare)){$(".compare-sidebar-content").empty();var list_id='';var json_compare=[];var check_end=0;var check=false;var compare_size=(Object.keys(compare).length-1);$.each(compare,function(index,el){var json_url="/products/"+el+".js";if($.trim(el)!=""){jQuery.getJSON(json_url,function(product){json_compare[check_end]=product;if((check_end>=compare_size)&&(check==false)){compare_to_div(json_compare);check=true;}
check_end+=1;});}});}else{compare={};$(".compare-sidebar-content").empty();$(".compare-sidebar-content").html("You Have No Item Compare");}
function kt_innit_carousel(){$('.owl-carousel.has-thumbs').owlCarousel({loop:true,items:1,thumbs:true,thumbsPrerendered:true,thumbImage:true,thumbContainerClass:'owl-thumbs',thumbItemClass:'owl-thumb-item'});$(".owl-carousel").each(function(index,el){var config=$(this).data();config.navText=['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'];var animateOut=$(this).data('animateout');var animateIn=$(this).data('animatein');var slidespeed=parseFloat($(this).data('slidespeed'));if(typeof animateOut!='undefined'){config.animateOut=animateOut;}
if(typeof animateIn!='undefined'){config.animateIn=animateIn;}
if(typeof(slidespeed)!='undefined'){config.smartSpeed=slidespeed;}
if($('body').hasClass('rtl')){config.rtl=true;}
var owl=$(this);owl.on('initialized.owl.carousel',function(event){var total_active=parseInt(owl.find('.owl-item.active').length);var i=0;owl.find('.owl-item').removeClass('item-first item-last');setTimeout(function(){owl.find('.owl-item.active').each(function(){i++;if(i==1){$(this).addClass('item-first');}
if(i==total_active){$(this).addClass('item-last');}});},100);})
owl.on('refreshed.owl.carousel',function(event){var total_active=parseInt(owl.find('.owl-item.active').length);var i=0;owl.find('.owl-item').removeClass('item-first item-last');setTimeout(function(){owl.find('.owl-item.active').each(function(){i++;if(i==1){$(this).addClass('item-first');}
if(i==total_active){$(this).addClass('item-last');}});},100);})
owl.on('change.owl.carousel',function(event){var total_active=parseInt(owl.find('.owl-item.active').length);var i=0;owl.find('.owl-item').removeClass('item-first item-last');setTimeout(function(){owl.find('.owl-item.active').each(function(){i++;if(i==1){$(this).addClass('item-first');}
if(i==total_active){$(this).addClass('item-last');}});},100);owl.addClass('owl-changed');setTimeout(function(){owl.removeClass('owl-changed');},config.smartSpeed)})
owl.on('drag.owl.carousel',function(event){owl.addClass('owl-changed');setTimeout(function(){owl.removeClass('owl-changed');},config.smartSpeed)})
owl.owlCarousel(config);if($(window).width()<992){var itembackground=$(".item-background");itembackground.each(function(index){if($('.item-background').attr("data-background")){$(this).css("background-image","url("+$(this).data("background")+")");$(this).css("height",$(this).closest('.owl-carousel').data("height")+'px');$('.slide-img').css("display",'none');}});}});}
function kt_get_scrollbar_width(){var $inner=jQuery('<div style="width: 100%; height:200px;">test</div>'),$outer=jQuery('<div style="width:200px;height:150px; position: absolute; top: 0; left: 0; visibility: hidden; overflow:hidden;"></div>').append($inner),inner=$inner[0],outer=$outer[0];jQuery('body').append(outer);var width1=parseFloat(inner.offsetWidth);$outer.css('overflow','scroll');var width2=parseFloat(outer.clientWidth);$outer.remove();return(width1-width2);}
$(document).on('click','.quick-view',function(event){event.preventDefault();$("#loading").fadeIn(300);var url=$(this).attr('href');$("#quick-view-modal").show();$("#quick-view-modal").load(url+" #product-single",function(){var window_size=parseFloat(jQuery('body').innerWidth());window_size+=kt_get_scrollbar_width();if(window_size>992){$.magnificPopup.open({items:{src:'#quick-view-modal',type:'inline'}});kt_innit_carousel();}
$("#loading").hide();});});function show_cart(data){if(data.item_count>0){var html='';$.each(data.items,function(index,el){html=html+'<li> <h3 class="cart-title"> '+el.title+'  </h3><div class="cart-image">';html=html+' <img src="'+el.image+'" width="50" /> </div> ';html=html+' <p> Price <strong> '+el.price+' </strong> </p>';html=html+' <p> Quantity <strong> '+el.quantity+' </strong> </p></li>';});$("#quick-view-modal").modal('show');$("#quick-view-modal .modal-content").html(html);}else{sweetAlert("Oops...","Cart is empty!","error");}}
$("#quick-view-modal button.close").on('click',function(){$('#modal-cart').remove();})
$(document).on('click',".email_to_friend",function(event){$("#email_to_friend").modal('show');})
function get_pid_variant(option){var result=$("#product-selectors").val();$("#product-selectors option").each(function(){var str=$(this).text();var arr=str.split(' - ');arr[arr.length]=$(this).val();var ok=true;var count=0;for(var i=0;i<option.length;i++){for(var j=0;j<arr.length;j++){if(option.length>1){var tg=arr[j].replace(/ /g,'');}else{var tg=arr[j];}
var val=tg.split('/');for(var k=0;k<val.length;k++){if(option[i]==val[k]){count++;}}}}
if(count!=option.length){ok=false;}
if(ok==true){result=arr[arr.length-1];}});return result;}
var change=false;var id_new="";$(document).on('change',".swatch :radio",function(event){var option=[];var index=0;change=true;if(change==true){$(".swatch-element input:checked:enabled").each(function(){option[index]=$(this).attr("value");index++;});id_new=get_pid_variant(option);$("#product-selectors").val(id_new).change();var text_option_id=$("#product-selectors option[value='"+id_new+"']").text();var arr=text_option_id.split(' - ');var new_price=arr[arr.length-1];$(".total-price span").text(new_price);$("#ProductPrice").text(new_price);var handle_product=$("#handle_product").val();$.getJSON('/products/'+handle_product+'.js',function(response){var variant_img="";var variant_img_id="";for(var i=0;i<response.variants.length;i++){if(response.variants[i].id==id_new&&response.variants[i].featured_image!=null){var compare_at_price=parseFloat(response.variants[i].compare_at_price)/100;$(".price .del").text(convert_currency_andy(response.variants[i].compare_at_price,'11'));if(response.variants[i].featured_image!=null){variant_img=response.variants[i].featured_image.src;variant_img_id=response.variants[i].featured_image.id;}
break;}}
if(variant_img!=""){var arr=variant_img.split('.');var new_img=arr[0]+"."+arr[1]+"."+arr[2]+"_468x539."+arr[3];$(".feature-slide-item").removeClass("active");$("#product-single .img-product img").attr("src",new_img);}});}});$(document).on('click',".feature-slide-item",function(event){$(".feature-slide-item").removeClass("active");$(this).addClass("active");var ulr_img=$(this).find(".thumbnai_img").attr("src");var arr=ulr_img.split('.');arr[2]=arr[2].replace("_126x143","");var new_img=arr[0]+"."+arr[1]+"."+arr[2]+"_468x539."+arr[3];$("#product-single .img-product img").attr("src",new_img);})
var option=[];var index=0;function hidePopupCart(){$(".cart-hover").removeAttr("style");}
$(document).on('click',".add_to_cart_button",function(event){$(this).attr('disabled','disabled').css('pointer-events','none');var $this=$(this);$this.css({'opacity':'.5'});event.preventDefault();var id=$("#productSelect").val();var quanlity=$("#Quantity").val();if(jQuery.type(id)==="undefined"&&jQuery.type(quanlity)==1){id=$(this).data('pid');quanlity=1;}else if(jQuery.type(quanlity)!=1&&jQuery.type(id)==="undefined"){id=$(this).data('pid');quanlity=quanlity;}
if(id_new!=""){id=id_new;}
$.ajax({url:'/cart/add.js',type:'POST',dataType:'json',data:{quantity:quanlity,id:id},success:function(response){show_dropdown_cart();$this.removeAttr("disabled").css('pointer-events','auto');$this.addClass('added-cart').attr('title',"View cart").attr("href","/cart");$this.removeClass("add_to_cart_button");if($this.hasClass("add-to-cart")){$this.text("View cart");}else{if($this.hasClass("added-cart")){$this.parent().addClass("active");}}
$this.css({'opacity':'1'});$("#compare-modal .close").trigger("click");$(".mfp-close").trigger("click");if(index==0){$('html, body').animate({scrollTop:$('#shopify-section-header').offset().top},'slow');index++;}else{swal("Success!","Added successfully to cart!","success");}
$(".cart-hover").css("opacity","1");$(".cart-hover").css("visibility","visible");setInterval(hidePopupCart,2000);}});});$(document).on("click",".btn-sold-out",function(){swal("Oops...","Sold out!","error");})
$(document).on('click',".remove-item",function(event){event.preventDefault();var id=$(this).data('id');$.ajax({url:'/cart/update.js',type:'POST',dataType:'json',data:"updates["+id+"]=0",success:function(data){$(".cartCost").text(convert_currency_andy(data.total_price));$(".cartCount").text(data.item_count);$("#item-"+id).remove();show_dropdown_cart();}});});$(document).on('change',"[id^='updates_']",function(event){event.preventDefault();var id=$(this).attr('id');var first_pos=id.indexOf('_')+1;var last_pos=id.length;var val_id=id.substr(first_pos,last_pos);var quanlity=$(this).val();$.ajax({url:'/cart/update.js',type:'POST',dataType:'json',data:"updates["+val_id+"]="+quanlity+"",success:function(data){$(".cartCost").text(convert_currency_andy(data.total_price));$(".cartCount").text(data.item_count);show_dropdown_cart();}});});$(document).on('click',".remove",function(event){event.preventDefault();var id=$(this).data('id');$.ajax({url:'/cart/update.js',type:'POST',dataType:'json',data:"updates["+id+"]=0",success:function(data){sweetAlert("Information","This item is removed!","success");window.location.reload();}});});$('#clear_all_cart').on('click',function(e){e.preventDefault();$.ajax({type:"POST",url:'/cart/clear.js',success:function(){sweetAlert("Information","Cart is cleared!","success");window.location.reload();},dataType:'json'});})
$(document).on("click",".icon-cart",function(event){event.preventDefault();show_dropdown_cart();});$(document).on('mouseover','.box-settings',function(){var names=$('.currency-lists li.active a').attr('class');var name_check=$('.currency-picker__wrapper .chosen-single span').first().text();if(names!=name_check){$('.currency-lists li').removeAttr("class");$('.currency-lists li a.'+name_check+'').parent("li").attr("class","active");}else{$('.currency-lists li a.'+name_check+'').parent("li").attr("class","active");}});$(document).on('click','.currency-lists .money',function(e){$('.currency-lists .money').removeClass("active");$(this).addClass("active");var name_currency=$(this).attr('data-value');$('.currency-picker__wrapper .chosen-single span').text(name_currency);$('.currency-picker').val(name_currency);$('.currency-picker').val(name_currency).change();Currency.cookie.write(name_currency);$('.lang-currency').empty();var id=$(this).attr("data-value");$('.lang-currency').append($(".currency-lists ."+id+" .code_currency").text());window.location.reload();});if(cookieCurrency!=null){$("."+cookieCurrency).addClass('active');$('.lang-currency').empty();$('.lang-currency').append($(".currency-lists ."+cookieCurrency+" .code_currency").text());};var first_click=false;$(document).on('click','.button-loadmore',function(event){event.preventDefault();var name=$(this).attr('name');var link=$(this).attr('title');$('#'+name+' li.hidden').css({'-webkit-animation-delay':'0ms','-o-animation-delay':'0ms','animation-delay':'0ms'});$('#'+name+' li.hidden').removeClass("hidden");$(this).text("VIEW MORE!");if(first_click){location.href='/collections/'+link;};first_click=true;})
$('[data-toggle=\'tooltip\']').tooltip({container:'body'});$("#scroll-top").on('click',function(event){event.preventDefault();$("html, body").animate({scrollTop:0},"slow");});var view_mode=$.localStorage;var get_view_mode=view_mode.get('view_mode');setTimeout(function(){if(get_view_mode=='grid'){$(".products-grid-view").trigger('click');}else{$(".products-list-view").trigger('click');}},2000);$('.products-grid-view').on('click',function(event){event.preventDefault();$('.products-change-view').removeClass('active');$(".products-grid-view").addClass('active');var view=$(this).data('view');view_mode.set('view_mode',view);$(".products").find('li.product-item').hide();$(".products").find('li.product-item.view-'+view).css('display','inline-block');if(view=='list'){$("#collection-product").removeClass('products-grid');$("#collection-product").addClass('products-list');}
if(view=='grid'){$("#collection-product").removeClass('products-list');$("#collection-product").addClass('products-grid');}
$(".page-number.current").trigger('click');});$('.products-list-view').on('click',function(event){event.preventDefault();$('.products-change-view').removeClass('active');$(".products-list-view").addClass('active');var view=$(this).data('view');view_mode.set('view_mode',view);$(".products").find('li.product-item').hide();$(".products").find('li.product-item.view-'+view).css('display','inline-block');if(view=='list'){$("#collection-product").removeClass('products-grid');$("#collection-product").addClass('products-list');}
if(view=='grid'){$("#collection-product").removeClass('products-list');$("#collection-product").addClass('products-grid');}
$(".page-number.current").trigger('click');});$(window).load(function(){$("[href^='#tab-1-']").trigger("click");});$(document).on("click",".btn_add_review",function(event){$(".review_tab").trigger("click");});$(document).on("click",".img-product-thumb",function(event){var url=$(this).find('img').attr('src');url=url.replace("small","1024x1024");$(".first-image").find('img').attr('src',url);});$(document).on("change","#search_option",function(event){$.cookie("remmember_search_option",$(this).val());$("#main_search").val("");})
if($.cookie("remmember_search_option")!=null){$("#search_option").val($.cookie("remmember_search_option"));var title_search_option=$('#search_option option:selected').text();$("#search_option_chosen a span").text(title_search_option);}
$(document).on("click","#search_option_chosen",function(event){var index=$('#search_option').prop('selectedIndex');$(".active-result").removeClass("highlighted");$(".active-result").removeClass("result-selected");$("[data-option-array-index^='"+index+"']").addClass("result-selected");$("#search_option").trigger("change");})
$(document).on("click",".btn-search",function(event){var val_search=$("#main_search").val();if(val_search!=""){$("#main_search").val($("#search_option").val()+":"+val_search);$(".form-search").submit();}else{window.location.reload();}
return false;})
$(document).on("keypress","#main_search",function(e){if(e.which==13){$(".form-search").submit();}})
$("[id^='span_'] a").on('click',function(){return true;})
$('[id^="chk_"]').on('click',function(e){var id=$(this).attr("name");var href=$("#span_"+id).find("a").attr('href');window.location.href=href;})
$("[id^='span_brand_'] a").on('click',function(){return true;})
$('[id^="chk_brand_"]').on('click',function(e){var id=$(this).attr("name");var href=$("#span_brand_"+id).find("a").attr('href');window.location.href=href;})
var count=0;if($("#Quantity").attr("data-max")==0){$("#Quantity").val($("#Quantity").attr("data-max"));}
$(".plus").on('click',function(e){count++;if(count>parseInt($("#Quantity").attr("data-max"))){$("#Quantity").val($("#Quantity").attr("data-max"));$(this).click(false);}else{$(this).click(true);}})
$(".minus").on('click',function(e){count--;if(count>parseInt($("#Quantity").attr("data-max"))){$("#Quantity").val($("#Quantity").attr("data-max"));$(this).click(false);}else{$(this).click(true);}
if(count<0){count=0;}})
$(".search-button").on('click',function(e){$(".search-block").submit();})
$(".close-menu").on("click",function(e){$(".header.layout2 .menu-item-has-children.show-submenu .submenu").css("display;","none");$(".header.layout2 .menu-item-has-children.show-submenu .submenu").css("opacity;","0");$(".header.layout2 .menu-item-has-children.show-submenu .submenu").css("visibility;","hidden");})
$("#btn_update").click(function(){$(".shoping-form").submit();})
$(".get-rates").click(function(){})
$("span.money").each(function(){var val=$(this).text();var arr=[];arr=val.split(" ");var newFormat=arr[0];$(this).text(newFormat);})
$(".product-item-actions .added").parent().addClass("active");$(".product-item-actions .view-added-wishlist").parent().addClass("active");$(function(){$('.lazy').lazy({});});$(".addthis_button_facebook_like").removeAttr("fb:like:layout");$(document).on("click",'.owl-next',function(){var parent=$(this).parent().parent().parent().attr("class");$(this).parent().parent().parent().addClass("lazy_active");$('.lazy_active img.lazy').lazy({bind:"event"});});$(document).on("click",'.owl-prev',function(){$(this).parent().parent().parent().addClass("lazy_active");$('.lazy_active img.lazy').lazy({bind:"event"});});$('.lazy').lazy({});$(window).scroll(function(event){$('.lazy').lazy({bind:"event",delay:0});});});})(jQuery);